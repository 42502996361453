/**
 * Get domain part of users email
 * @param {*} user
 * @returns {String|null} domain
 */
export function getUserEmailDomain(user) {
  let domain = null;

  if (user.email && typeof user.email === "string") {
    const parts = user.email.split("@");
    if (parts.length > 0) {
      domain = parts[parts.length - 1];
    }
  }

  return domain;
}

/**
 * Previous course completions time limit:
 * Check if course completions are recent enough.
 * @param {*} courseinfo Courses list from getHasRequiredAccomplishments response.
 * @param {*} validfordays 
 */
export function checkCompletionsValidityPeriod(courseinfo, validfordays) {
  if (validfordays === null) return true;
  if (!courseinfo) return true;
  // Aikaraja: aloitettu aikaisintaan
  let limitdate = new Date(Date.now());
  limitdate.setDate(limitdate.getDate() - validfordays);
  limitdate = limitdate.getTime();

  let recent = false;
  for (const c of courseinfo) {
    if (c.completed_time > limitdate) {
      recent = true;
      break;
    }
  }
  return recent;
}

export function checkTimedCourseQualification(registrationinfo, timelimit) {
  if (registrationinfo?.registration_time && timelimit !== null) {
    // aikaraja: aloitettu aikaisintaan
    let limitdate = new Date(Date.now());
    limitdate.setDate(limitdate.getDate() - timelimit);
    limitdate = limitdate.getTime();

    if (limitdate > registrationinfo?.registration_time) {
      return false;
    }
  }
  // ei aloitettu tai ei rajoitettu tai aloitettu aikarajan sisällä: kelpaa
  return true; 
}